import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

import { SEO, Layout, PageHeader, OnClickButton } from "components"
import MenuPage from "../templates/menu-page"

const Page = ({ data }) => (
  <Layout>
    <SEO title="Dinner Menu" />
    <PageHeader title="Dinner Menu">
      <StaticImage
        src="../images/page-header.jpg"
        placeholder="blurred"
        alt="Menu"
        quality="100"
      />
    </PageHeader>
    <MenuPage data={data}>
      <div className="text-center">
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://hhsclientfiles.s3.amazonaws.com/spruzzo/menu/dinner0422.pdf"
          download
        >
          <OnClickButton>Download Dinner Menu</OnClickButton>
        </a>
      </div>
    </MenuPage>
  </Layout>
)

export default Page

export const query = graphql`
  {
    allInternalResData(
      filter: {
        name: {
          in: [
            "Fresh Salads"
            "Appetizers"
            "Daily Homemade Soups"
            "Side Orders"
            "Dinner Entrees"
            "Dinner Pastas"
            "Speciality Pizzas"
          ]
        }
      }
    ) {
      nodes {
        name
        description
        id
        items {
          name
          description
          id
          price
          sizes {
            name
            price
            menu_item_id
          }
        }

        groups {
          name
          menu_id
          options {
            price
            name
            id
          }
        }
      }
    }
  }
`
